import React, { useEffect } from 'react'
import Container from '@material-ui/core/Container'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Picture from '../../components/Picture'

import VideoModal from '../../components/ModalVideo'
import BoxFeature from '../../components/FeaturesLPs/box'
import Feature from '../../components/FeaturesLPs/feature'
import TitleFeature from '../../components/FeaturesLPs/title'
import ImageFeature from '../../components/FeaturesLPs/image'
import VideoWithModal from '../../components/VideoWithModal'

import CardTecnologia from '../../components/CardTecnologia'
import FormNewsletter from '../../components/Newsletter'

import { ditoTrack } from '../../helpers/dito'

import {
  bannerTopoLg,
  bannerTopoMd,
  bannerTopoXs,
  featureCabedal1,
  featureCabedal2,
  featureSola1,
  featureSola2,
  featureSola3,
  featureSola4,
  nimbus23Cabedal,
  nimbus23Laranja,
  nimbus23Rosa,
  nimbus23Sola,
  nimbus23Verde,
  nimbus23Verde2,
  runningGuys,
  runningGuysXs,
  thumbVideo,
} from '../../images/gelnimbus23'

import * as S from './styles'
import { graphql } from 'gatsby'

const GelNimbus23Page = () => {
  useEffect(() => {
    ditoTrack('acessou-nimbus-23')
  }, [])

  return (
    <Layout>
      <SEO title="GEL NIMBUS-23™" />

      <VideoModal videoId="QE_yvI588uE" />

      <Picture
        xs={bannerTopoXs}
        md={bannerTopoMd}
        lg={bannerTopoLg}
        alt="GEL NIMBUS-23™ - Conforto que vai longe"
      />

      <S.About>
        <S.AboutText>
          <h1>
            Mais leve, mais confortável para distâncias ainda maiores
            <br />
            <strong>GEL-NIMBUS ™ 23</strong>
          </h1>
        </S.AboutText>

        <Picture
          xs={runningGuysXs}
          md={runningGuys}
          lg={runningGuys}
          alt="Mais leve, mais confortável para distâncias ainda maiores"
          className="about-image"
        />
      </S.About>

      <VideoWithModal
        classname="gel-nimbus23"
        title="Inspire-se em quem"
        subtitle="vai mais longe"
        thumb={thumbVideo}
      />

      <S.RecursosTecno>
        <Container>
          <h2>novos recursos e tecnologias</h2>
          <h3>garantem amortecimento para qualquer distância.</h3>

          <S.RecursosTecnoThumb>
            <Picture
              xs={nimbus23Rosa}
              md={nimbus23Rosa}
              lg={nimbus23Rosa}
              alt="GEL NIMBUS-23™ Rosa e Azul"
            />
            <Picture
              xs={nimbus23Verde}
              md={nimbus23Verde}
              lg={nimbus23Verde}
              alt="GEL NIMBUS-23™ Verde"
            />
          </S.RecursosTecnoThumb>
        </Container>
      </S.RecursosTecno>

      <S.FeaturesWrapper>
        <BoxFeature>
          <div className="item">
            <ImageFeature
              src={nimbus23Cabedal}
              alt="Cabedal"
              classname="thumb-cabedal"
            />
          </div>
          <div className="item">
            <TitleFeature>
              <strong>novidades</strong>
              <br />
              no cabedal:
            </TitleFeature>

            <S.FeaturesList>
              <Feature>
                <img src={featureCabedal1} alt="feature Cabedal" />
                <p>
                  <strong>Engineered Mesh ™ 3D</strong>
                  <br />
                  Calce perfeito e cabedal respirável para pés mais frescos
                </p>
              </Feature>
              <Feature>
                <img src={featureCabedal2} alt="feature Cabedal" />
                <p>
                  <strong>Colarinho acolchoado</strong>
                  <br />e melhor acabamento interno com PALMILHA ORTHOLITE X-55
                  que garante suavidade e mais conforto
                </p>
              </Feature>
            </S.FeaturesList>
          </div>
        </BoxFeature>

        <BoxFeature reverse>
          <div className="item">
            <ImageFeature
              src={nimbus23Sola}
              alt="Sola"
              classname="thumb-sola"
              reverse
            />
          </div>
          <div className="item">
            <TitleFeature>
              sola e entressola com
              <br />
              <strong>ainda mais recursos:</strong>
            </TitleFeature>

            <S.FeaturesList>
              <Feature>
                <img src={featureSola1} alt="feature Sola" />
                <p>
                  <strong>GEL ™</strong>
                  <br />
                  Dianteiro e traseiro para máxima absorção de impacto
                </p>
              </Feature>
              <Feature>
                <img src={featureSola2} alt="feature Sola" />
                <p>
                  <strong>FLYTEFOAM ™ e FLYTEFOAM ™ PROPEL</strong>
                  <br />
                  Garante o conforto e leveza, além de oferecer uma corrida mais
                  responsiva com mais amortecimento
                </p>
              </Feature>
              <Feature>
                <img src={featureSola3} alt="feature Sola" />
                <p>
                  <strong>TRUSSTIC ™ GENDER-SPECIFC</strong>
                  <br />
                  Mais segurança e estabilidade, evitando torções
                </p>
              </Feature>
              <Feature>
                <img src={featureSola4} alt="feature Sola" />
                <p>
                  <strong>AHAR+ ™:</strong>
                  <br />
                  Mais durável com borracha carbonada
                </p>
              </Feature>
            </S.FeaturesList>
          </div>
        </BoxFeature>
      </S.FeaturesWrapper>

      <S.ModelosWrapper>
        <S.ModelosTituloWrapper>
          <strong>VEJA O QUE MUDOU</strong>
        </S.ModelosTituloWrapper>

        <S.ModelosContentTecnologia>
          <S.ModelosItemTecnologia>
            <img src={nimbus23Laranja} alt="gel-nimbus ™ 22" />
            <h2>gel-nimbus ™ 22</h2>
            <CardTecnologia
              title="cabedal"
              tecnologias={[
                'AHAR™;',
                'FLYTEFOAM™ PROPEL;',
                'GEL™;',
                'GUIDANCE TRUSSTIC™',
              ]}
              bgColor="#00b388"
              classname="card-gelnimbus23"
            />
          </S.ModelosItemTecnologia>

          <S.ModelosItemTecnologia>
            <img src={nimbus23Verde2} alt="gel-nimbus ™ 23" />
            <h2>gel-nimbus ™ 23</h2>
            <CardTecnologia
              title="cabedal"
              tecnologias={[
                'GEL™ traseiro e dianteiro;',
                'FLYTEFOAM™ e FLYTEFOAM ™ PROPEL;',
                'TRUSSTIC ™ GENDER-SPECIFC;',
                'Palmilha ORTHOLITE X-55;',
                'Engineered Mesh ™ 3D',
              ]}
              bgColor="#00b388"
              classname="card-gelnimbus23"
            />
          </S.ModelosItemTecnologia>
        </S.ModelosContentTecnologia>
      </S.ModelosWrapper>

      <S.OndeComprarWrapper>
        <div className="container">
          <h2>Especificações</h2>
          <p>
            Drop = 10 mm (M)
            <br />
            Drop = 13 mm (F)
            <br />
            Peso = 310g (M)
            <br />
            Peso = 260g (F)
          </p>
          <span>R$ 799,99</span>

          <S.ButtonComprarWrapper>
            <S.ButtonComprar
              href="https://www.asics.com.br/calcados?filtro_87=GEL-Nimbus-23&utm_source=lab&utm_medium=lp&utm_campaign=crm-lp-nimbus-23&paged=1"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              comprar agora
            </S.ButtonComprar>
            <S.ButtonComprar
              href="https://api.whatsapp.com/send/?phone=551150568450&text&app_absent=0"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              comprar pelo whatsapp
            </S.ButtonComprar>
          </S.ButtonComprarWrapper>
        </div>
      </S.OndeComprarWrapper>

      <S.WrapperNewsletter>
        <S.ContentNewsletter>
          <h2>Cadastre seu e-mail para receber ofertas e novidades</h2>

          <S.FormNewsletter>
            <FormNewsletter isLP origem="lab-nimbus23" />
          </S.FormNewsletter>
        </S.ContentNewsletter>
      </S.WrapperNewsletter>
    </Layout>
  )
}

export default GelNimbus23Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
