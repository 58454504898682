import styled from 'styled-components'

import { bgFeatures, bgContato } from '../../images/gelnimbus23'

export const About = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 767px) {
    flex-direction: row-reverse;
    justify-content: center;
  }

  @media (min-width: 1900px) {
    .about-image {
      width: auto;
    }
  }
`

export const AboutText = styled.div`
  background-color: #fff;
  padding: 40px;

  h1 {
    color: var(--primary-color);
    font-size: 28px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    text-align: center;

    @media (min-width: 767px) {
      text-align: left;
    }
  }

  strong {
    color: var(--secondary-color);
    display: block;
    font-size: 38px;
    font-style: italic;
    line-height: 46px;
  }
`

export const RecursosTecno = styled.section`
  background-color: #fff;
  padding: 60px 0 0px 0;
  width: 100%;

  .MuiContainer-root {
    height: 100%;
    text-align: center;
  }

  h2 {
    color: var(--primary-color);
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    line-height: 32px;
    text-transform: uppercase;

    @media (min-width: 767px) {
      line-height: 14px;
    }
  }

  h3 {
    color: var(--primary-color);
    font-size: 30px;
    font-style: italic;
    font-weight: 400;
    line-height: 32px;
  }
`

export const RecursosTecnoThumb = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  padding: 30px 15px;

  @media (min-width: 767px) {
    flex-direction: row;
    gap: 0;
    margin: 50px 0;
    padding: 0;

    img {
      width: 350px;
    }
  }
`

export const FeaturesWrapper = styled.section`
  background-image: url(${bgFeatures});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px 0 0 0;
`

export const FeaturesList = styled.div`
  margin: 30px 0;
  width: 550px;

  @media (max-width: 767px) {
    width: 100%;
  }
`

export const ModelosWrapper = styled.section`
  background-color: #fff;
  padding: 80px 0 30px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }
`

export const ModelosTituloWrapper = styled.h1`
  color: var(--primary-color);
  font-size: 50px;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 50px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 32px;
  }
`

export const ModelosContentTecnologia = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    flex-flow: row;
  }

  @media (min-width: 1024px) {
    max-width: 1000px;
  }
`

export const ModelosItemTecnologia = styled.div`
  position: relative;

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 50px;
    text-align: center;
  }

  h2 {
    color: var(--primary-color);
    font-size: 34px;
    font-family: 'ASICSFont3.0-BoldItalic';
    margin: 15px 0;
    text-align: center;
    text-transform: uppercase;
  }

  img {
    display: block;
    height: 179px;
    margin: 0 auto;
    object-fit: contain;
    width: 370px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`

export const OndeComprarWrapper = styled.section`
  background-color: #fff;
  padding: 0 0 80px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 0 0 30px 0;
  }

  .container {
    margin: 0 auto;
    max-width: 1070px;
    padding-left: 45px;
    position: relative;
    width: 100%;

    @media (max-width: 767px) {
      padding-left: 0;
      text-align: center;
    }
  }

  h2,
  p {
    color: var(--primary-color);
  }

  h2 {
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 30px;
    font-style: italic;
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 26px;
    }
  }

  p {
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 24px;
    width: 530px;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 20px;
      padding: 0 55px;
      width: 100%;
    }
  }

  span {
    color: var(--secondary-color);
    font-size: 60px;
    font-style: italic;
    font-weight: bold;
  }
`

export const ButtonComprarWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;

  @media (max-width: 767px) {
    position: initial;
  }
`

export const ButtonComprar = styled.a`
  background-color: var(--primary-color);
  border-radius: 99999px;
  color: #fff;
  display: block;
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 16px;
  margin: 10px 0;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
  width: 265px;

  &:hover {
    background-color: #fff;
    color: var(--primary-color);
  }
`

export const WrapperNewsletter = styled.section`
  background-image: url(${bgContato});
  background-position: center top;
  background-size: cover;
  padding: 50px 0;
  width: 100%;

  @media (max-width: 991px) {
    padding: 30px 30px 0 30px;
  }
`

export const ContentNewsletter = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  width: 100%;

  h2 {
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 28px;
    margin-bottom: 5px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 26px;
    }
  }
`

export const FormNewsletter = styled.div`
  margin: 30px auto;
  width: 60%;

  @media (max-width: 991px) {
    width: 100%;
  }
`
